import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import Cookies from 'js-cookie'
import Api from '@/models/Api.js'

Vue.use(Vuex)

Vue.config.devtools = true

function isItemInCart(cartItems, item) {
  let result;
  if (item.post_type == 'gear') {
    if (item.hasOwnProperty('notes') && item.notes.includes('Size')) {
      result = cartItems.findIndex((e) => e['ID'] == item['ID'] && e.notes == item.notes);
    } else {
      result = cartItems.findIndex((e) => e['ID'] == item['ID']);
    }
  } else {
    result = cartItems.findIndex((e) => e['ID'] == item['ID'] && e.type == item.type);
  }
  if (result != -1) {
    return result;
  } else {
    return false;
  }
}

const persistedState = createPersistedState({
  paths: ['cartItems', 'cartLastUpdated']
})

export default new Vuex.Store({
  plugins: [persistedState],
  state: {
    cartItems: [],
    cartLastUpdated: null,
    previousOrders: [],
    orderInfo: {
      shippingMethod: null,
      shippingAddress: null,
      billingAddress: null,
      shippingDestination: 'freight',
      liftgate: null,
      appointmentFee: false,
      shippingNotes: '',
      pickupPhone: null,
      pickupDatetime: null,
      arrangedInstructions: null,
      deliveryAvailability: '',
      xpoGuaranteedService: false,
      xpoGuaranteedServiceDate: '',
      oldDominionGuaranteedService: false,
      oldDominionGuaranteedServiceDate: '',
      discounts: [
        // {
        //   name: 'Online Discount',
        //   sub: '',
        //   discount: '-2',
        //   type: 'percentage',
        //   target: 'order'
        // }
      ],
      discountAccepted: false,
      ccInfo: {
        number: '',
        firstName: '',
        lastName: '',
        month: '',
        year: '',
        cvv: ''
      },
      achInfo: {
        routingNumber: '',
        accountNumber: '',
        agree: false
      },
      paymentMethod: 'Credit Card',
      poNumber: null
    },
    orderStep: 'products',
    orderStepData: null,
    orderSteps: [
      'products',
      'cart',
      'shipping',
      'billing',
      'review'
    ],
    prevStep: null,
    palletUnits: 48,
    boxUnits: 1,
    drumUnits: 12,
    toteUnits: 48,
    pouchUnits: 0.64,
    lemonadeUnits: 0.34,
    pourableFruitUnits: 0.34,
    userData: null,
    boxWeight: 46,
    drumWeight: 475,
    toteWeight: 2450,
    pouchWeight: 20,
    lemonadeWeight: 17,
    pourableFruitWeight: 17,
    shippingAddresses: [],
    billingAddresses: [],
    addresses: [],
    orderUrl: null,
    productsCurrentTab: 'purees'
  },
  mutations: {
    addToCart(state, item) {
      if (item.qty == 0) return;
      let cartItemIndex = isItemInCart(state.cartItems, item);
      if (cartItemIndex !== false) {
        item.qty = parseInt(item.qty) + parseInt(state.cartItems[cartItemIndex].qty);
        state.cartItems.splice(cartItemIndex, 1, item);
      } else {
        state.cartItems.push(item);
      }
      state.orderInfo.shippingMethod = null;
      state.cartLastUpdated = Date.now();
      this.commit('applyProductDiscountPrices');
    },
    removeFromCart(state, index) {
      let item = state.cartItems[index];
      state.cartItems.splice(index, 1);
      if (state.cartItems.length == 0) {
        state.orderStep = 'products';
      }
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(["trackEvent", { id: 'Removed Item From Cart: '+item.post_title }]);
    },
    resetCart(state) {
      state.cartItems = [];
    },
    setCartLastUpdated(state, lastUpdated) {
      state.cartLastUpdated = lastUpdated;
    },
    setPreviousOrders(state, previousOrders) {
      state.previousOrders = previousOrders;
    },
    addDiscount(state, discount) {
      state.orderInfo.discounts.push(discount);
      state.orderInfo.discountAccepted = true;
    },
    applyProductDiscountPrices(state) {
      let productDiscounts = state.orderInfo.discounts.filter(discount => discount.target == 'products');
      productDiscounts.forEach(discount => {
        discount.products.forEach(product => {
          let matchedCartItems = state.cartItems.filter(cartItem => cartItem['ID'] == product['ID']);
          if (!matchedCartItems) return;
          if (discount.type == 'fixed') {
            matchedCartItems.forEach(matchedCartItem => {
              if (matchedCartItem.prices.hasOwnProperty(matchedCartItem.type+'Original')) return;
              let discountedPrice = matchedCartItem.prices[matchedCartItem.type];
              switch (matchedCartItem.type) {
                case 'box':
                  discountedPrice = discount.boxDiscount;
                  break;
                case 'drum':
                  discountedPrice = discount.drumDiscount;
                  break;
                case 'tote':
                  discountedPrice = discount.toteDiscount;
                  break;
                case 'pouch':
                  discountedPrice = discount.pouchDiscount;
                  break;
                case 'lemonade':
                  discountedPrice = discount.lemonadeDiscount;
                  break;
                case 'pourable_fruit_ecom':
                  discountedPrice = discount.pourableFruitDiscount;
                  break;
              }
              matchedCartItem.prices[matchedCartItem.type+'Original'] = matchedCartItem.prices[matchedCartItem.type];
              matchedCartItem.prices[matchedCartItem.type] = parseFloat(discountedPrice).toFixed(2);
            });
          }
          if (discount.type == 'percentage') {
            matchedCartItems.forEach(matchedCartItem => {
              // Only check for Original - we want to fall back to general discount
              if (matchedCartItem.prices.hasOwnProperty(matchedCartItem.type+'Original')) return;
              
              let discountedPrice = parseFloat(matchedCartItem.prices[matchedCartItem.type]);
              let finalDiscount = null;
              
              switch (matchedCartItem.type) {
                case 'box':
                  finalDiscount = discount.boxDiscount;
                  break;
                case 'drum':
                  finalDiscount = discount.drumDiscount;
                  break;
                case 'tote':
                  finalDiscount = discount.toteDiscount;
                  break;
                case 'pouch':
                  finalDiscount = discount.pouchDiscount;
                  break;
                case 'lemonade':
                  finalDiscount = discount.discount; // Use general discount for lemonade
                  break;
                case 'pourable_fruit_ecom':
                  finalDiscount = discount.discount; // Use general discount for pourable fruit
                  break;
              }
              
              // Only proceed if we have a valid discount
              if (finalDiscount == 0 || finalDiscount == null) return;
              
              // Store original price first
              matchedCartItem.prices[matchedCartItem.type+'Original'] = matchedCartItem.prices[matchedCartItem.type];
              
              // For a -50% discount, we want price * 0.5
              // So for finalDiscount of -50, we do: price * (Math.abs(-50)/100) = price * 0.5
              discountedPrice = discountedPrice * (Math.abs(finalDiscount)/100);
              
              matchedCartItem.prices[matchedCartItem.type] = parseFloat(discountedPrice).toFixed(2);
            });
          }
        });
      });
    },
    restoreProductDiscountPrices(state) {
      let types = [
        'box',
        'drum',
        'tote',
        'pouch'
      ];
      state.cartItems.forEach(cartItem => {
        types.forEach(type => {
          if (type+'Original' in cartItem.prices) {
            cartItem.prices[type] = cartItem.prices[type+'Original'];
            delete cartItem.prices[type+'Original'];
          }
        });
      });
    },
    addDiscountCode(state, discountCode) {
      if (discountCode == '') return false;
      Api.post('discount/code', { code: discountCode }).then(response => {
        if (response) {
          if (response.target == 'products') {
            this.commit('addDiscount',
              {
              name: discountCode + ' Discount',
              sub: response.description,
              boxDiscount: response.box_discount,
              drumDiscount: response.drum_discount,
              toteDiscount: response.tote_discount,
              pouchDiscount: response.pouch_discount,
              discount: response.order_discount,
              type: response.discount_type,
              target: response.target,
              products: response.products
              }
            );
            this.commit('applyProductDiscountPrices');
            this._vm.$overlay(true, 'Your discount code ('+discountCode+') was applied!', 3000);
            return true;
          }
          if (response.target == 'order') {
            let existingDiscountIndex = state.orderInfo.discounts.findIndex(discount => discount.target == 'shipping' || discount.target == 'order');
            state.orderInfo.discounts.splice(existingDiscountIndex, 1);
            this.commit('addDiscount',
              {
              name: discountCode + ' Discount',
              sub: response.description,
              boxDiscount: response.box_discount,
              drumDiscount: response.drum_discount,
              toteDiscount: response.tote_discount,
              discount: response.order_discount,
              pouchDiscount: response.pouch_discount,
              type: response.discount_type,
              target: response.target,
              products: response.products
              }
            );
            this._vm.$overlay(true, 'Your discount code ('+discountCode+') was applied!', 3000);
            return true;
          }
          if (response.target == 'shipping') {
            let existingDiscountIndex = state.orderInfo.discounts.findIndex(discount => discount.target == 'shipping' || discount.target == 'order');
            state.orderInfo.discounts.splice(existingDiscountIndex, 1);
            this.commit('addDiscount',
              {
              name: discountCode + ' Discount',
              sub: response.description,
              discount: response.order_discount,
              type: response.discount_type,
              target: response.target,
              states: response.states
              }
            );
            this._vm.$overlay(true, 'Your discount code ('+discountCode+') was applied!', 3000);
            return true;
          }
        }
        return false;
      });
    },
    setPoNumber(state, poNumber) {
      state.orderInfo.poNumber = poNumber;
    },
    setDiscounts(state, discounts) {
      state.orderInfo.discounts = discounts;
    },
    setShippingMethod(state, shippingMethod) {
      state.orderInfo.shippingMethod = shippingMethod;
    },
    setShippingAddress(state, shippingAddress) {
      state.orderInfo.shippingAddress = shippingAddress;
    },
    setShippingDestination(state, shippingDestination) {
      state.orderInfo.shippingDestination = shippingDestination;
    },
    setLiftgate(state, val) {
      state.orderInfo.liftgate = val;
    },
    setAppointmentFee(state, val) {
      state.orderInfo.appointmentFee = val;
    },
    setShippingNotes(state, val) {
      state.orderInfo.shippingNotes = val;
    },
    setPaymentMethod(state, paymentMethod) {
      state.orderInfo.paymentMethod = paymentMethod;
    },
    setBillingAddress(state, billingAddress) {
      state.orderInfo.billingAddress = billingAddress;
    },
    setCcInfo(state, ccInfo) {
      state.orderInfo.ccInfo = ccInfo;
    },
    setAchInfo(state, achInfo) {
      state.orderInfo.achInfo = achInfo;
    },
    setOrderInfo(state, { key, value }) {
      state.orderInfo[key] = value;
    },
    setOrderStepData(state, orderStepData) {
      state.orderStepData = orderStepData;
    },
    changeOrderStep(state, step) {
      let isObject = false;
      let originalStep = step;
      if (step !== null && typeof step === 'object') {
        step = step.step;
        isObject = true;
      }
      state.prevStep = state.orderStep;
      if ((step == 'cart' || step == 'shipping' || step == 'billing') && state.cartItems.length == 0) return;
      window.location.hash = step;
      state.orderStep = step;
      if (document.querySelector("#fermentation-products #nav")) {
        document.querySelector("#fermentation-products #nav").scrollIntoView({
          behavior: 'smooth'
        });
      }
      if (isObject === true) {
        state.orderStepData = originalStep.data;
      }
      document.title = 'Ordering - '+step.charAt(0).toUpperCase() + step.slice(1);
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', window.location.pathname+'#'+step]);
      _hsq.push(['trackPageView']);
    },
    prevStep(state) {
      if (state.prevStep != null) {
        state.orderStep = state.prevStep;
      }
    },
    setUserData(state, userData) {
      Cookies.set('userData', userData, { expires: 1 });
      state.userData = userData;
      // Set Hubspot Identity
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(["identify",{
        email: state.userData.email
      }]);
      _hsq.push(["trackEvent", { id: 'Logged In' }]);

      window.uetq = window.uetq || [];
      window.uetq.push('set', { 'pid': { 
        'em': state.userData.email, // Replace with the variable that holds the user's email address. 
        //'ph': '+14250000000', // Replace with the variable that holds the user's phone number. 
      }});
    },
    logout(state) {
      Cookies.remove('userData');
      state.userData = null;
      state.orderStep = 'products';
    },
    trackHubspotEvent(state, event) {
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(["trackEvent", event]);
    },
    getUserAddresses(state) {
      Api.getAuth('account/addresses?customer-id='+state.userData.id, state.userData.token).then(response => {
        state.addresses = response;
        state.shippingAddresses = (Array.isArray(response)) ? response.filter((el) => { return el.type == 'shipping'; }) : [];
        state.billingAddresses = (Array.isArray(response)) ? response.filter((el) => { return el.type == 'billing'; }) : [];
      });
    },
    getPreviousOrders(state) {
      Api.getAuth('account/'+state.userData.id+'/orders', state.userData.token).then(response => {
        state.previousOrders = response;
      });
    },
    resetOrder(state) {
      state.orderInfo = {
        shippingMethod: null,
        shippingAddress: null,
        billingAddress: null,
        shippingDestination: 'freight',
        liftgate: null,
        appointmentFee: false,
        shippingNotes: '',
        pickupPhone: null,
        pickupDatetime: null,
        arrangedInstructions: null,
        discounts: [],
        discountAccepted: false,
        deliveryAvailability: '',
        xpoGuaranteedService: false,
        oldDominionGuaranteedService: false,
        guaranteedServiceDate: '',
        ccInfo: {
          number: '',
          firstName: '',
          lastName: '',
          month: '',
          year: '',
          cvv: ''
        },
        paymentMethod: 'Credit Card',
        poNumber: null
      };
      state.cartItems = [];
    },
    setOrderUrl(state, orderUrl) {
      state.orderUrl = orderUrl;
    },
    setProductsCurrentTab(state, currentTab) {
      state.productsCurrentTab = currentTab;
    }
  },
  getters: {
    cartTotal: state => {
      let total = 0;
      state.cartItems.forEach(cartItem => {
        total = parseFloat(total) + (parseFloat(cartItem.prices[cartItem.type]) * cartItem.qty);
      });
      return total.toFixed(2);
    },
    cartTotalType: state => (type) => {
      let total = 0;
      state.cartItems.forEach(cartItem => {
        if (cartItem.post_type != type) return;
        total = parseFloat(total) + (parseFloat(cartItem.prices[cartItem.type]) * cartItem.qty);
      });
      return total.toFixed(2);
    },
    cartNonDiscountedProductsTotalByType: state => (type) => {
      let total = 0;
      state.cartItems.forEach(cartItem => {
        if (cartItem.post_type != type) return;
        if (Object.keys(cartItem.prices).some(key => key.includes('Original'))) return;
        total = parseFloat(total) + (parseFloat(cartItem.prices[cartItem.type]) * cartItem.qty);
      });
      return total.toFixed(2);
    },
    cartDiscountedProductsTotalByType: state => (type) => {
      let total = 0;
      state.cartItems.forEach(cartItem => {
        if (cartItem.post_type != type) return;
        if (Object.keys(cartItem.prices).some(key => key.includes('Original')) == false) return;
        total = parseFloat(total) + (parseFloat(cartItem.prices[cartItem.type]) * cartItem.qty);
      });
      return total.toFixed(2);
    },
    cartPreorders: (state) => {
      let preorders = [];
      state.cartItems.forEach(cartItem => {
        if (cartItem.hasOwnProperty(cartItem.type+'_out_of_stock_override')) {
          preorders.push(cartItem);
        }
      });
      return preorders;
    },
    cartFinalTotal: (state, getters) => {
      let total = 0;
      total += parseFloat(getters.cartTotalType('puree'));
      total += parseFloat(getters.cartTotalType('concentrate'));
      total += parseFloat(getters.cartTotalType('lemonade'));
      total += parseFloat(getters.cartTotalType('pourable_fruit_ecom'));
      total += parseFloat(getters.cartTotalType('gear'));
      // Apply order percentage discounts
      state.orderInfo.discounts.forEach(discount => {
        if (discount.type == 'percentage' && discount.target == "order") {
          total += parseFloat(getters.cartNonDiscountedProductsTotalByType('puree')) * parseFloat(discount.discount * .01);
          total += parseFloat(getters.cartNonDiscountedProductsTotalByType('concentrate')) * parseFloat(discount.discount * .01);
        }
      });
      // Use the getter for shipping price with discounts
      if (state.orderInfo.shippingMethod) {
        total += parseFloat(getters.shippingPriceWithDiscounts);
      }
      // Additional fees
      if (state.orderInfo.liftgate) {
        total += 45;
      }
      if (state.orderInfo.xpoGuaranteedService) {
        total += 75;
      }
      if (state.orderInfo.oldDominionGuaranteedService) {
        total += 50;
      }
      if (state.orderInfo.appointmentFee) {
        total += 15;
      }
      return total.toFixed(2);
    },
    typeTotals: state => {
      const typeTotals = {
        boxes: 0,
        drums: 0,
        totes: 0,
        pouches: 0,
        lemonades: 0,
        pourableFruits: 0
      };
      state.cartItems.forEach(cartItem => {
        let typeKey = `${cartItem.type}s`; // Convert type to plural form
        if (cartItem.type == 'pourable_fruit_ecom') {
          typeKey = 'pourableFruits';
        }
        if (typeTotals.hasOwnProperty(typeKey)) {
          typeTotals[typeKey] += parseInt(cartItem.qty, 10);
        }
      });
      return typeTotals;
    },
    shippingDiscountAmount: (state, getters) => {
      if (!state.orderInfo.shippingMethod) {
        return 0;
      }
      const shippingPrice = parseFloat(state.orderInfo.shippingMethod.price);
      let shippingDiscountAmount = 0;
      state.orderInfo.discounts.forEach(discount => {
        if (discount.target === "shipping" && discount.type === "percentage" && state.orderInfo.shippingDestination === "freight" && state.orderInfo.shippingMethod.name != "Fedex Ground" && getters.orderShippingAddress.length != 0 && discount.states.includes(getters.orderShippingAddress.state) && getters.isSingleRemainingPalletSpace == false) {
          shippingDiscountAmount = shippingPrice * (.01 * parseFloat(discount.discount));
        }
      });
      return parseFloat(shippingDiscountAmount).toFixed(2);
    },
    shippingPriceWithDiscounts: (state, getters) => {
      if (!state.orderInfo.shippingMethod) {
        return 0;
      }
      let shippingPrice = parseFloat(state.orderInfo.shippingMethod.price);
      const discountAmount = parseFloat(getters.shippingDiscountAmount);
      shippingPrice += discountAmount;
      return shippingPrice.toFixed(2);
    },
    cartShippingItems: (state) => {
      let pallets = [{ count: 0, weight: 0 }];
      let pallet = null;
      let pourableFruits = state.cartItems.filter((cartItem) => { return cartItem.type == 'pourable_fruit_ecom'; });
      let lemonades = state.cartItems.filter((cartItem) => { return cartItem.type == 'lemonade'; });
      let pouches = state.cartItems.filter((cartItem) => { return cartItem.type == 'pouch'; });
      let totes = state.cartItems.filter((cartItem) => { return cartItem.type == 'tote'; });
      let drums = state.cartItems.filter((cartItem) => { return cartItem.type == 'drum'; });
      let boxes = state.cartItems.filter((cartItem) => { return cartItem.type == 'box'; });

      pourableFruits.forEach(pourableFruit => {
        let weight = state.pourableFruitWeight;
        for (let i=1;i <= pourableFruit.qty;i++) {
          pallet = pallets.pop();
          if ((pallet.count + state.pourableFruitUnits) > state.palletUnits) {
            pallets.push(pallet);
            pallets.push({ count: state.pourableFruitUnits, weight: weight });
          } else {
            pallet.count = pallet.count + state.pourableFruitUnits;
            pallet.weight = pallet.weight + weight;
            pallets.push(pallet);
          }
        }
      });

      lemonades.forEach(lemonade => {
        let weight = state.lemonadeWeight;
        for (let i=1;i <= lemonade.qty;i++) {
          pallet = pallets.pop();
          if ((pallet.count + state.lemonadeUnits) > state.palletUnits) {
            pallets.push(pallet);
            pallets.push({ count: state.lemonadeUnits, weight: weight });
          } else {
            pallet.count = pallet.count + state.lemonadeUnits;
            pallet.weight = pallet.weight + weight;
            pallets.push(pallet);
          }
        }
      });

      pouches.forEach(pouch => {
        let weight = (pouch.pouch_weight != null && pouch.pouch_weight != '') ? parseInt(pouch.pouch_weight) : state.pouchWeight;
        for (let i=1;i <= pouch.qty;i++) {
          pallet = pallets.pop();
          if ((pallet.count + state.pouchUnits) > state.palletUnits) {
            pallets.push(pallet);
            pallets.push({ count: state.pouchUnits, weight: weight });
          } else {
            pallet.count = pallet.count + state.pouchUnits;
            pallet.weight = pallet.weight + weight;
            pallets.push(pallet);
          }
        }
      });

      totes.forEach(tote => {
        let weight = (tote.tote_weight != null && tote.tote_weight != '') ? parseInt(tote.tote_weight) : state.toteWeight;
        for (let i=1;i <= tote.qty;i++) {
          pallet = pallets.pop();
          if ((pallet.count + state.toteUnits) > state.palletUnits) {
            pallets.push(pallet);
            pallets.push({ count: state.toteUnits, weight: weight });
          } else {
            pallet.count = pallet.count + state.toteUnits;
            pallet.weight = pallet.weight + weight;
            pallets.push(pallet);
          }
        }
      });

      drums.forEach(drum => {
        let weight = (drum.drum_weight != null && drum.drum_weight != '') ? parseInt(drum.drum_weight) : state.drumWeight;
        for (let i=1;i <= drum.qty;i++) {
          pallet = pallets.pop();
          if ((pallet.count + state.drumUnits) > state.palletUnits) {
            pallets.push(pallet);
            pallets.push({ count: state.drumUnits, weight: weight });
          } else {
            pallet.count = pallet.count + state.drumUnits;
            pallet.weight = pallet.weight + weight;
            pallets.push(pallet);
          }
        }
      });

      boxes.forEach(box => {
        let weight = (box.box_weight != null && box.box_weight != '') ? parseInt(box.box_weight) : state.boxWeight
        for (let i=1;i <= box.qty;i++) {
          pallet = pallets.pop();
          if ((pallet.count + state.boxUnits) > state.palletUnits) {
            pallets.push(pallet);
            pallets.push({ count: state.boxUnits, weight: weight });
          } else {
            pallet.count = pallet.count + state.boxUnits;
            pallet.weight = pallet.weight + weight;
            pallets.push(pallet);
          }
        }
      });

      return pallets;
    },
    typeWeights: (state, getters) => {
      let typeWeights = {
        boxes: getters.typeTotals['boxes'] * state.boxWeight,
        drums: getters.typeTotals['drums'] * state.drumWeight,
        totes: getters.typeTotals['totes'] * state.toteWeight,
        pouches: getters.typeTotals['pouches'] * state.toteWeight
      };
      typeWeights.total = typeWeights.boxes + typeWeights.drums + typeWeights.totes + typeWeights.pouches;
      return typeWeights;
    },
    pallets: (state, getters) => { // eslint-disable-line no-unused-vars
      return (getters.cartShippingItems[0].count != 0) ? getters.cartShippingItems.length : 0;
    },
    isSingleRemainingPalletSpace: (state, getters) => { // eslint-disable-line no-unused-vars 
      let remainingUnits = state.palletUnits - getters.cartShippingItems[getters.cartShippingItems.length - 1].count;
      if (remainingUnits == 0 || getters.pallets > 1) return false;
      return true;
    },
    remainingPalletSpace: (state, getters) => { // eslint-disable-line no-unused-vars 
      let remainingUnits = state.palletUnits - getters.cartShippingItems[getters.cartShippingItems.length - 1].count;
      return {
        boxes: Math.floor(remainingUnits / state.boxUnits),
        drums: Math.floor(remainingUnits / state.drumUnits),
        totes: Math.floor(remainingUnits / state.toteUnits),
        pouches: Math.floor(remainingUnits / state.pouchUnits),
        lemonades: Math.floor(remainingUnits / state.lemonadeUnits),
        pourableFruits: Math.floor(remainingUnits / state.pourableFruitUnits)
      };
    },
    addressById: (state) => (id) => {
      return state.addresses.filter((el) => { return el.id == id; })[0];
    },
    orderShippingAddress: (state) => {
      return state.addresses.filter((el) => { return el.id == state.orderInfo.shippingAddress; })[0];
    },
    orderBillingAddress: (state) => {
      return state.addresses.filter((el) => { return el.id == state.orderInfo.billingAddress; })[0];
    },
    isShippingAddressComplete: (state) => {
      return state.orderInfo.shippingAddress != null;
    },
    isBillingAddressComplete: (state) => {
      return state.orderInfo.billingAddress != null;
    },
    isCcInfoComplete: (state) => {
      return state.orderInfo.ccInfo.number != "" && state.orderInfo.ccInfo.month != "" && state.orderInfo.ccInfo.year != "" && state.orderInfo.ccInfo.cvv != "";
    },
    isOrderComplete: (state, getters) => {
      return state.orderInfo.shippingMethod != null && state.orderInfo.paymentMethod != false && ((getters.isBillingAddressComplete && getters.isCcInfoComplete) || (getters.isBillingAddressComplete && state.orderInfo.paymentMethod == 'Invoice') || (getters.isBillingAddressComplete && state.orderInfo.paymentMethod == 'ACH' && state.orderInfo.achInfo.agree == true) || state.orderInfo.paymentMethod == 'Free');
    },
    isDiscountApplied: (state, getters) => {
      // Check for item-level discounts
      const itemDiscountApplied = state.cartItems.some(cartItem => {
        return Object.keys(cartItem.prices).some(key => {
          // Check if the item has an 'Original' price and it differs from the current price
          if (key.includes('Original')) {
            const type = key.replace('Original', ''); // e.g., 'boxOriginal' -> 'box'
            return cartItem.prices[key] !== cartItem.prices[type];
          }
          return false;
        });
      });
    
      // Check for shipping discount
      const shippingDiscountApplied = state.orderInfo.shippingMethod
        ? parseFloat(getters.shippingDiscountAmount) !== 0
        : false;
    
      return itemDiscountApplied || shippingDiscountApplied;
    },    
    discountAmount: (state) => (discount) => {
      if (discount.target === 'shipping') {
        // Handle shipping discounts separately
        return parseFloat(state.orderInfo.shippingMethod?.price || 0) * (discount.discount * 0.01);
      }
    
      if (discount.type === 'percentage' || discount.type === 'fixed') {
        let totalSaved = 0;
    
        // Loop through all cart items
        state.cartItems.forEach(cartItem => {
          const originalPriceKey = `${cartItem.type}Original`;
    
          // Ensure original price exists and is valid
          if (originalPriceKey in cartItem.prices) {
            const originalPrice = parseFloat(cartItem.prices[originalPriceKey]);
            const currentPrice = parseFloat(cartItem.prices[cartItem.type]);
    
            // Accumulate the total savings (original - current) * quantity
            totalSaved += (originalPrice - currentPrice) * cartItem.qty;
          }
        });
    
        return totalSaved.toFixed(2); // Return the total amount saved
      }
    
      return 0.00; // Default case for unsupported discount types
    },
    applyDiscountIfExists: (state) => (item, price) => {
      state.orderInfo.discounts.every(discount => {
        if (discount.target != 'products') {
          return true;
        }
        let matchedProduct = discount.products.find(product => product['ID'] == item['ID']);
        if (matchedProduct == false) {
          return false;
        }
        if (discount.type == 'fixed') {
          switch (item.type) {
            case 'box':
              price = discount.boxDiscount;
              return false;
            case 'drum':
              price = discount.drumDiscount;
              return false;
            case 'tote':
              price = discount.toteDiscount;
              return false;
            case 'pouch':
              price = discount.pouchDiscount;
              return false;
          }
        }

        if (discount.type == 'fixed-price') {
          switch (item.type) {
            case 'box':
              price = price - discount.boxDiscount;
              return false;
            case 'drum':
              price = price - discount.drumDiscount;
              return false;
            case 'tote':
              price = price - discount.toteDiscount;
              return false;
            case 'pouch':
              price = price - discount.pouchDiscount;
              return false;
          }
        }

        if (discount.type == 'percentage') {
          switch (item.type) {
            case 'box':
              price = price - (price*discount.boxDiscount);
              return false;
            case 'drum':
              price = price - (price*discount.drumDiscount);
              return false;
            case 'tote':
              price = price - (price*discount.toteDiscount);
              return false;
            case 'pouch':
              price = price - (price*discount.pouchDiscount);
              return false;
          }
        }
        
        return true;
      });
      return price;
    },
    samplesInCart: (state) => {
      let totalSamples = 0;
      let samplesInCart = state.cartItems.filter(cartItem => cartItem.post_type == 'sample');
      samplesInCart.forEach(sample => {
        totalSamples = parseInt(totalSamples) + parseInt(sample.qty);
      });
      return totalSamples;
    }
  }
})
